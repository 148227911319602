<template>
    <div>
        <app-layout>
            <Header :title="$t('prep_attendance')" :is-filter="false"/>
            <HeaderMobile :title="$t('prep_attendance')" :is-filter="false"/>
            <div>
                <div class="row mb-4">
                    <div class="col-12 col-sm-6 col-lg-3 col-xxl-2">
                        <b-form-group :label="$t('module')">
                            <active-module-selectbox
                                type="english"
                                v-on:activeModule="activeModule"
                                v-model="moduleSelected"
                            ></active-module-selectbox>
                        </b-form-group>
                    </div>
                    <div class="col-12 col-sm-6 col-lg-4 col-xxl-3">
                        <b-form-group
                            :label="$t('attendance_date')"
                            class="position-relative"
                        >
                            <v-date-picker
                                v-model="date"
                                :disabledDates="arrayDates"
                                locale="tr"
                                is-expanded
                                :popover="{ visibility: 'click' }"
                                :attributes="attributes"
                                :show-current="false"
                                @input="changeDate"
                                @update:to-page="updatePickedMonth"
                            >
                                <template v-slot="{ inputValue, inputEvents }">
                                    <b-input-group>
                                        <b-form-input
                                            autocomplete="off"
                                            :disabled="class_id==null"
                                            autofill="off"
                                            readonly="readonly"
                                            :placeholder="$t('select_date')"
                                            :value="inputValue"
                                            v-on="inputEvents"
                                        ></b-form-input>
                                        <b-input-group-append>
                                            <b-button variant="outline-secondary" class="btn-40"
                                                      @click="printRoster()"
                                                      v-b-popover.hover.top="$t('print_roster')"
                                            ><i class="ri-printer-line"></i>
                                            </b-button>
                                        </b-input-group-append>
                                    </b-input-group>
                                </template>
                            </v-date-picker>
                        </b-form-group>
                    </div>
                    <div class="w-100 d-none d-lg-block"></div>
                    <div class="col-12 col-lg-3 col-xxl-2">
                        <div
                            class="
                border
                p-4
                rounded
                overflow-y-scroll overflow-x-hidden
                w-100
                d-none d-lg-block
              "
                            style="height: calc(100vh - 220px);overflow-y: scroll"
                        >
                            <div v-if="isModule === true">
                                <div v-if="isSchedulesInstructor === true">
                                    <div
                                        v-for="(item, key) in setSchedulesInstructor"
                                        v-bind:key="key"
                                    >
                                        <b-button
                                            :variant="(item[0].class_id==class_id?'outline-primary':'outline-secondary')+' btn-block justify-content-start highhonor-auto font-weight-medium line-height-normal py-2 text-left mb-2 h-auto'"
                                            @click="setSchedulesCalendar(key, item[0].class_id)"
                                        >
                                            {{ key }}
                                        </b-button>
                                    </div>
                                </div>
                                <div v-else>
                                    <div class="spinner-border text-light mx-auto d-flex"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 col-lg-9 col-xxl-10">
                        <div v-if="isTable === true">
                            <div v-if="isStudents === true">
                                <div
                                    class="mb-0 table-dropdown border rounded table-responsive"
                                    style="height: calc(100vh - 280px); min-height: 300px; overflow-y: scroll"
                                >
                                    <table
                                        role="table"
                                        aria-busy="false"
                                        class="table b-table table-striped table-bordered"
                                    >
                                        <thead role="rowgroup">
                                        <tr role="row">
                                            <th
                                                role="columnheader"
                                                scope="col"
                                                tabindex="0"
                                                :aria-colindex="{ key }"
                                                aria-sort="descending"
                                                class="table-b-table-default"
                                                v-for="(item, key) in fields"
                                                v-bind:key="key"
                                                @click="sort(item.key)"
                                            >
                                                <div>{{ item.label }}</div>
                                            </th>
                                        </tr>
                                        </thead>
                                        <tbody role="rowgroup">
                                        <tr
                                            role="row"
                                            v-for="(item, key) in innerSetStudents"
                                            v-bind:key="key"
                                        >
                                            <td :aria-colindex="{ key }" role="cell">
                                                {{ item.student_number }}
                                            </td>
                                            <td :aria-colindex="{ key }" role="cell">
                                                {{ item.name_surname }}
                                            </td>

                                            <td
                                                aria-colindex="3"
                                                role="cell"
                                                v-for="scheduleId in scheduleIds"
                                                v-bind:key="scheduleId"
                                            >
                                                <div
                                                    class="custom-control custom-control-inline custom-checkbox"
                                                >
                                                    <div
                                                        class="btn-group btn-group-toggle"
                                                        data-toggle="buttons"
                                                    >
                                                        <label
                                                            :class="
                                  'btn ' +
                                  (item.hours[scheduleId].absent == '0'
                                    ? 'btn-success active'
                                    : 'btn-secondary')
                                "
                                                        >
                                                            <input
                                                                @click="item.hours[scheduleId].absent = '0'"
                                                                type="radio"
                                                                :name="scheduleId + '_' + item.student_number"
                                                                autocomplete="off"
                                                                :disabled="(typeof item.hours[scheduleId].not_allow_update != 'undefined'?item.hours[scheduleId].not_allow_update:false)"
                                                            />{{ $t("present") }}
                                                        </label>
                                                        <label
                                                            :class="
                                  'btn ' +
                                  (item.hours[scheduleId].absent == '1'
                                    ? 'btn-danger active'
                                    : 'btn-secondary')
                                "
                                                        >
                                                            <input
                                                                @click="item.hours[scheduleId].absent = '1'"
                                                                type="radio"
                                                                :name="scheduleId + '_' + item.student_number"
                                                                autocomplete="off"
                                                                :disabled="(typeof item.hours[scheduleId].not_allow_update != 'undefined'?item.hours[scheduleId].not_allow_update:false)"
                                                            />{{ $t("absent") }}
                                                        </label>
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div class="d-flex mt-4">
                                    <b-button
                                        variant="primary"
                                        class="mr-2"
                                        @click="showModal('saveModal')"
                                    >{{ $t("save") }}
                                    </b-button>
                                    <b-button
                                        variant="success"
                                        class="mr-2"
                                        @click="showModal('publishModal')"
                                    >{{ $t("publish") }}
                                    </b-button>
                                </div>
                            </div>
                            <div v-else>
                                <div class="spinner-border text-light mx-auto d-flex"></div>
                            </div>
                        </div>
                    </div>
                </div>

                <b-modal
                    ref="saveModal"
                    id="saveModal"
                    :size="size"
                    centered
                    :title="$t('warning')"
                >
                    <template>
                        <p class="my-4">
                            {{ $t("prep_attendance_confirmation_modal") }}
                        </p>
                    </template>
                    <template #modal-footer>
                        <div class="w-100">
                            <b-button
                                variant="primary"
                                size="sm"
                                class="float-right"
                                @click="save"
                            >
                                {{ $t("confirmation") }}
                            </b-button>
                        </div>
                    </template>
                </b-modal>

                <b-modal
                    ref="publishModal"
                    id="publishModal"
                    :size="size"
                    centered
                    :title="$t('warning')"
                >
                    <template>
                        <p class="my-4">
                            {{ $t("prep_attendance_publish_modal") }}
                        </p>
                    </template>
                    <template #modal-footer>
                        <div class="w-100">
                            <b-button
                                variant="primary"
                                size="sm"
                                class="float-right"
                                @click="publish"
                            >
                                {{ $t("confirmation") }}
                            </b-button>
                        </div>
                    </template>
                </b-modal>
            </div>
        </app-layout>
    </div>
</template>
<script>
import AppLayout from "@/layouts/AppLayout";
import PrepAttendanceService from "@/services/PrepAttendance";
import moment from "moment";
import ActiveModuleSelectbox from "@/components/interactive-fields/ActiveModuleSelectbox";
import Header from "@/layouts/AppLayout/Header";
import HeaderMobile from "@/layouts/AppLayout/HeaderMobile";
import qs from "qs";

export default {
    components: {
        HeaderMobile,
        Header,
        ActiveModuleSelectbox,
        AppLayout,
    },
    metaInfo() {
        return {
            title: this.$t("prep_attendance"),
        };
    },
    data() {
        return {
            isModule: false,
            class_id: null,
            level_class: null,
            isSchedulesInstructor: false,
            setSchedulesInstructor: [],
            theDate: moment(new Date()).format("YYYY-MM-DD"),
            selectDate: null,
            isStudents: false,
            setStudents: [],
            scheduleIds: [],
            isTable: false,
            size: "md",
            selected: "",
            date: null,
            CommonModalMode: false,
            moduleSelected: null,
            arrayDates: [],
            fields: [],
            attributes: [],
            month: null,
            sorted: {}
        };
    },
    watch: {
        moduleSelected: function (newValue) {
            this.isModule = true;
            this.setModule(newValue);
        },
    },
    computed: {
        innerSetStudents(){
            return this.setStudents
        }
    },
    methods: {
        sort(key){
            this.sorted[key] = !this.sorted[key]

            if(key === "name_surname") {
                if(!this.sorted[key]){
                    this.setStudents.reverse()
                    return
                }
                this.setStudents.sort((a,b) => a[key].localeCompare(b[key]))
            } else if(key === "student_number"){
                if(!this.sorted[key]){
                    this.setStudents.reverse()
                    return
                }
                this.setStudents.sort((a,b) => a[key]- b[key])
            }
        },
        showModal(modalName) {
            this.$refs[modalName].show();
        },
        hideModal(modalName) {
            this.$refs[modalName].hide();
        },

        refresh() {
            this.date = null;
            this.fields = [];
            this.scheduleIds = [];
            this.selectDate = null;
            this.isStudents = false;
            this.setStudents = [];
            this.isTable = false;
            this.arrayDates = [];
        },

        updatePickedMonth(val, update=false) {
            if (this.month != null && val.month === this.month && !update) {
                return false;
            }
            this.month = val.month;

            PrepAttendanceService.getSchedulesCalendar(
                this.class_id,
                parseInt(val.month),
                val.year
            ).then((response) => {
                this.getDaysInMonth(parseInt(val.month), val.year, response.data.data);
            });
        },

        getDaysInMonth(month, year, schedulesCalendar) {
            var getMonthDays = new Date(year, month, 0).getDate();
            var list = [];
            var i;
            for (i = 1; i <= getMonthDays; i++) {
                list.push(
                    moment(year + "-" + month + "-" + i, 'YYYY-MM-DD"').format(
                        "YYYY-MM-DD"
                    )
                );
            }
            var result = list.filter(
                (item) => !Object.keys(schedulesCalendar).includes(item)
            );
            this.arrayDates = result;

            let attributes = [];
            Object.keys(schedulesCalendar).map(function (value, index) {
                var status;
                if (schedulesCalendar[value].attendance_status === 0) {
                    status = "red";
                } else if (schedulesCalendar[value].attendance_status === 2) {
                    status = "blue";
                } else {
                    status = "yellow";
                }

                attributes.push({
                    dot: status,
                    demo: value,
                    dates: [new Date(value), new Date(value)],
                });
            });

            this.attributes = attributes;
        },

        setSchedulesCalendar(key, class_id) {
            this.refresh();
            if (this.class_id != class_id) {
                this.month = null;
            }
            this.class_id = class_id;
            this.level_class = key;
            var checkDate = moment(this.theDate, "YYYY-MM-DD");
            var month = parseInt(checkDate.format("MM"));
            var year = checkDate.format("YYYY");

            this.updatePickedMonth({month: month, year: year});
        },

        setModule(selected) {
            if (!selected) {
                return;
            }
            this.refresh();
            this.isSchedulesInstructor = false;
            this.month = null;
            PrepAttendanceService.getSchedulesInstructor(selected).then(
                (response) => {
                    this.isSchedulesInstructor = true;
                    this.setSchedulesInstructor = response.data.data;
                }
            );
        },

        getStudents(class_id, date) {
            this.isTable = true;
            this.isStudents = false;

            const config = {
                params: {
                    filter: {
                        class_id: class_id
                    },
                    limit: -1
                },
                paramsSerializer: (params) => qs.stringify(params, {encode: false})
            };

            PrepAttendanceService.getStudents(config).then((response) => {
                if (response.data.success === true) {
                    this.setStudents = response.data.data;

                    var listStudent = [];
                    let scheduleIds = this.scheduleIds;
                    response.data.data.map(function (value, key) {
                        let attendance = [];
                        scheduleIds.forEach((scheduleId) => {
                            attendance[scheduleId] = {absent: 0};
                        });
                        listStudent[value.student_program_id] = {
                            id: value.id,
                            student_number: value.student_number,
                            name_surname: value.name + " " + value.surname,
                            student_program_id: value.student_program_id,
                            hours: attendance,
                        };
                    });

                    scheduleIds.map((scheduleId) => {
                        PrepAttendanceService.getPrepAttendancesStudents(
                            scheduleId,
                            moment(new Date(date)).format("YYYY-MM-DD")
                        ).then((re) => {
                            if (re.data.success === true) {
                                listStudent.map((itemStudent, indexStudent) => {
                                    if (re.data.data[itemStudent.student_program_id] && re.data.data[itemStudent.student_program_id][0]) {
                                        itemStudent.hours[scheduleId].absent =
                                            re.data.data[itemStudent.student_program_id][0].absent;
                                    }
                                });
                            }
                        });
                    });

                    this.isStudents = true;
                    this.setStudents = Object.values(listStudent);
                }
            });
        },

        changeDate(date) {
            if (!this.class_id || date==null) {
                return;
            }
            var month = moment(new Date(date)).format("MM");
            var year = moment(new Date(date)).format("YYYY");
            this.selectDate = moment(new Date(date)).format("DD/MM/YYYY");
            this.theDate = moment(new Date(date)).format("YYYY-MM-DD");
            var list = [];
            var scheduleIds = [];
            PrepAttendanceService.getSchedulesCalendar(
                this.class_id,
                month,
                year
            ).then((response) => {
                if (response.data.success === true) {
                    list.push({
                        key: "student_number",
                        label: this.toUpperCase("student_number"),
                        sortable: true,
                    });
                    list.push({
                        key: "name_surname",
                        label: this.toUpperCase("name_surname"),
                        sortable: true,
                    });

                    let courseLabel = this.$t("course");
                    response.data.data[
                        moment(new Date(date)).format("YYYY-MM-DD")
                        ].hours.map(function (value, index) {
                        scheduleIds.push(value.id);
                        list.push({
                            id: value.id,
                            key: "checkbox_" + Math.floor(Math.random() * 10000),
                            label:
                                courseLabel +
                                " " +
                                (index + 1) +
                                " (" +
                                value.start_time +
                                " - " +
                                value.end_time +
                                ")",
                            sortable: true,
                        });
                    });

                    this.fields = list;
                    this.scheduleIds = scheduleIds;
                    this.getStudents(this.class_id, date);
                }
            });
        },

        save() {
            if(this.checkPermission('prepattendancehour_store')){
                let attendances = [];
                this.setStudents.forEach((item) => {
                    Object.keys(item.hours).forEach((scheduleId) => {
                        let attendance = {
                            schedule_id: parseInt(scheduleId),
                            student_program_id: parseInt(item.student_program_id),
                            absent: parseInt(item.hours[scheduleId].absent),
                        };
                        attendances.push(attendance);
                    });
                });
                const dateObj = moment(this.selectDate, "DD/MM/YYYY");
                const date = dateObj.format("YYYY-MM-DD");
                let data = {
                    date: date,
                    attendances: attendances,
                };
                PrepAttendanceService.postPrepAttendancesHours(data)
                                     .then((res) => {
                                         this.hideModal("saveModal");
                                         this.$toast.success(this.$t("api." + res.data.message));
                                         this.updatePickedMonth({month: dateObj.month(), year: dateObj.year()}, true);
                                     })
                                     .catch((e) => {
                                         if (e.data.message) {
                                             this.$toast.error(this.$t("api." + e.data.message));
                                         }
                                     });
            } else {
                this.$toast.error(this.$t("you_are_not_authorized_for_this_operation"))
            }

        },

        publish() {
            if(this.checkPermission('prepattendance_finalize')){
                const dateObj = moment(this.selectDate, "DD/MM/YYYY");
                const date = dateObj.format("YYYY-MM-DD");
                let data = {
                    date: date,
                    schedule_id: this.scheduleIds,
                };
                PrepAttendanceService.putPrepAttendancesFinalized(data)
                                     .then((res) => {
                                         this.hideModal("publishModal");
                                         this.$toast.success(this.$t("api." + res.data.message));
                                         this.updatePickedMonth({month: dateObj.month(), year: dateObj.year()}, true);
                                     })
                                     .catch((e) => {
                                         if (e.data.message) {
                                             this.$toast.error(this.$t("api." + e.data.message));
                                         }
                                     });
            } else {
                this.$toast.error(this.$t("you_are_not_authorized_for_this_operation"))
            }

        },
        printRoster(){
            if(this.checkPermission('prepattendance_roster')){
                if(!this.class_id){
                    return;
                }
                PrepAttendanceService.roster({class_id: this.class_id, date: this.theDate})
                                     .then((response) => {
                                         this._downloadFile(response, this.level_class+'_'+this.theDate+'.pdf')
                                     })
                                     .catch((e) => {
                                         this.showErrors(e, null, true);
                                     });
            } else {
                this.$toast.error(this.$t("you_are_not_authorized_for_this_operation"))
            }
        },
        activeModule(module){
            this.moduleSelected = module;
        }
    },
    created() {
    },
    mounted() {
    },
};
</script>
